import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { InvoiceService } from "../../services/invoice.service";
declare var $: any;

@Component({
  selector: "app-update-card",
  templateUrl: "./update-card.component.html",
  styleUrls: ["./update-card.component.css"],
})
export class UpdateCardComponent implements OnInit {
  public isActive: any;
  public changed = true;
  public card: any;
  public status = 'Active';
  public error = "";

  constructor(
    public dialogRef: MatDialogRef<UpdateCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public invoiceService: InvoiceService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.error = "";
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker({
        iconBase: "fa",
        tickIcon: "fa-check"
      });
    }



    if ($(".selectpicker1").length != 0) {
      $(".selectpicker1").selectpicker({
        iconBase: "fa",
        tickIcon: "fa-check"
      });
    }
    this.isActive = this.data.status;
    this.card = {
      drivername: this.data.drivername,
      unit: this.data.unit,
      pin: this.data.pin,
      limit_ulsd: this.data.limit_ulsd,
      limit_rfr: this.data.limit_rfr,
      limit_defd: this.data.limit_defd,
      cashadvance_limit: this.data.cashadvance_limit,
      cashadvance_cycle: this.data.cashadvance_cycle,
      status: this.data.status == 'Inactive' ? "Inactive": "Active",
    }
    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker('val', this.card.status);
    }
    if ($(".selectpicker1").length != 0) {
      $(".selectpicker1").selectpicker('val', this.card.cashadvance_cycle);
    }
  }

  public onCloseClick(e) {
    e.preventDefault();
    this.dialogRef.close(false);
  }

  public onSubmit(value, isValid) {
    if (isValid) {
      const body = {
        ...value,
        card: this.data.card,
        status: value["status"],
      };
      this.spinner.show();
      this.invoiceService.updateCardDetails(body).subscribe(
        (data) => {
          this.spinner.hide();
          this.dialogRef.close(true);
        },
        (error) => {
          if(error == 'Service Unavailable') {
            this.error = "Something went wrong, please try again later. For urgencies contact; support@ecopetroleum.ca"
          }
          this.spinner.hide();
        }
      );
    }
  }

}
