import { NgModule} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import {DragDropModule} from '@angular/cdk/drag-drop';

import { AppComponent }   from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { PagesnavbarModule} from './shared/pagesnavbar/pagesnavbar.module';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { SharedService } from './app.service';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { ErrorInterceptor } from './helper/error.interceptor';
import { APIInterceptor } from './services/interceptor';
import { LoginComponent } from './components/login/login.component';
import { MoneyCodeComponent } from './components/money-code/money-code.component';
import { BrowserModule } from '@angular/platform-browser';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { CardsComponent } from './components/cards/cards.component';
import { SupportComponent } from './components/support/support.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { RebateCalculatorComponent } from './components/rebate-calculator/rebate-calculator.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UpdateCardComponent } from './components/update-card/update-card.component';
import { CreateTemplateComponent } from './components/create-template/create-template.component';



@NgModule({
    imports:      [
    FormsModule,
        BrowserAnimationsModule,        
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        
        
        HttpModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        PagesnavbarModule,
        MatDatepickerModule,
        HttpClientModule,
        ReactiveFormsModule,
        //BrowserModule,
        CommonModule,
        JwBootstrapSwitchNg2Module,
        NgxSpinnerModule,
        DragDropModule,
  ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        LoginComponent,
        MoneyCodeComponent,
        CardsComponent,
        SupportComponent,
        TransactionsComponent,
        InvoiceComponent,
        RebateCalculatorComponent,
        ChangePasswordComponent,
        UpdateCardComponent,
        CreateTemplateComponent,
  ],  

    providers: [
      SharedService,
   { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
   {
     provide: HTTP_INTERCEPTORS,
     useClass: APIInterceptor,
     multi: true,
   },
 ],
    bootstrap:    [ AppComponent ]
})

export class AppModule { }
