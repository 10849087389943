import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
//import { MatDatepicker } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { saveAs } from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { InvoiceService } from "../../services/invoice.service";

declare var $: any;

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.css"],
})
export class InvoiceComponent implements OnInit {
  @ViewChild("htmlData") htmlData: ElementRef;

  public displayedColumns: string[] = [
    "company_name",
    "tran_date",
    "card",
    "city",
    "unit",
    // "odometer",
    "state",
    // "driver_name",
    "disc_ppu",
    "qty",
    // "pretax",
    // "tax",
    "amt",
    "currency",
  ];
  public columnsHeading = [
    "Company Id",
    "Tansaction Date",
    "Card",
    "City",
    "Unit",
    //"Odometer",
    "State",
    // "Driver Name",
    "Disc Ppu",
    "Quantity",
    //"PreTax",
    //"Tax",
    "Amount",
    "Currency",
  ];
  public typeCtrl: FormControl = new FormControl();
  public length = 10;
  public message = "";
  public transactions: any;
  public start_date = new Date();
  public end_date = new Date();
  public currency = "";
  public company_address: any;
  public company_name: any;
  public company_phone: any;
  public company_contact: any;
  public total_amount: any;
  public total_tax: any;
  public maxDate = "";
  public company = [];
  public company_filter = "";
  public product = "";
  public tableData1: any;

  constructor(
    private activatedRout: ActivatedRoute,
    public invoiceService: InvoiceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker({
        iconBase: "fa",
        tickIcon: "fa-check"
      });
    }

    setTimeout(() => {
      if ($(".selectpicker1").length != 0) {
        $(".selectpicker1").selectpicker({
          iconBase: "fa",
          tickIcon: "fa-check"
        });
      }
    }, 100);

    $('.datepicker1').datetimepicker({
      format: 'MM/DD/YYYY',    //use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      this.start_date = e.date.toDate();
    });

    $('.datepicker2').datetimepicker({
      format: 'MM/DD/YYYY',    //use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      this.end_date = e.date.toDate();
    });

    let date = new Date();
    this.start_date.setDate(date.getDate() - 6);
    this.activatedRout.data.subscribe((data) => {
      if (data && data["data"]["message"]) {
        this.message = data["data"]["message"];
      }
      this.transactions = data["data"]["data"];
      this.company_address = data.data["company_address"];
      this.company_name = data.data["company_name"];
      this.company_phone = data.data["company_phone"];
      this.company_contact = data.data["company_contact"];
      this.total_amount = data.data["total_amount"];
      this.total_tax = data.data["total_tax"];
      this.company = data.data["company"];
      this.transactions.map((item)=> {
        item.card = item.card.toString().replace(/.(?=.{4})/g, '');
      })

      this.tableData1 = {
        headerRow: this.columnsHeading,
        dataRows: this.transactions
      };
    });
  }

  onDateChange(event, type) {
    if (type === "start_date") {
      this.start_date = new Date(event.value);
    }
    if (type === "end_date") {
      this.end_date = new Date(event.value);
    }
  }
  loadFilteredInvoiceData() {
    let datepipe = new DatePipe("en-US");
    let startDate =
      datepipe.transform(this.start_date, "yyyy-MM-dd") + "T00:00:00Z";
    let endDate =
      datepipe.transform(this.end_date, "yyyy-MM-dd") + "T00:00:00Z";
    if (!this.company_filter) {
      this.company_filter = "All";
    }
    this.spinner.show();

    this.invoiceService
      .filterInvoice(startDate, endDate, this.currency, this.company_filter, this.product)
      .subscribe((data) => {
        this.spinner.hide();

        this.transactions = data["data"];
        this.company_address = data["company_address"];
        this.company_name = data["company_name"];
        this.company_phone = data["company_phone"];
        this.company_contact = data["company_contact"];
        this.total_amount = data["total_amount"];
        this.total_tax = data["total_tax"];
        this.transactions.map((item)=> {
          item.card = item.card.toString().replace(/.(?=.{4})/g, '');
        })
        this.tableData1 = {
          headerRow: this.columnsHeading,
          dataRows: this.transactions
        };
      }, (error) => {
        this.spinner.hide();
      });
  }

  public downloadPDF(): void {
    let datepipe = new DatePipe("en-US");
    let startDate =
      datepipe.transform(this.start_date, "yyyy-MM-dd") + "T00:00:00Z";
    let endDate =
      datepipe.transform(this.end_date, "yyyy-MM-dd") + "T00:00:00Z";
    if (!this.company_filter) {
      this.company_filter = "All";
    }
    this.spinner.show();
    this.invoiceService
      .getInvoiceFileName(this.currency, startDate, endDate, this.company_filter, this.product)
      .subscribe((fileData) => {
        let fileName = `${fileData["filename"]}`;
        this.invoiceService
          .dowloadInvoice(
            this.currency,
            startDate,
            endDate,
            this.company_filter
          )
          .subscribe((data) => {
            this.spinner.hide();
            saveAs(data as unknown as string, fileName)
          }, error => {
            this.spinner.hide();
          });
      }, error => {
        this.spinner.hide();
      });
  }
}
