import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
//import { MatDatepicker } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceService } from '../../services/invoice.service';
declare var $: any;

@Component({
  selector: 'app-rebate-calculator',
  templateUrl: './rebate-calculator.component.html',
  styleUrls: ['./rebate-calculator.component.css']
})
export class RebateCalculatorComponent implements OnInit {

  @ViewChild('htmlData') htmlData: ElementRef;

  public displayedColumns: string[] = ["company_name", "card", "tran_date", "unit", "odometer", "disc_ppu", "city", "state", "driver_name", "qty", "amt", "currency"]
  public columnsHeading = [
    "Company Name",
    "Card",
    "Tansaction Date",
    "Unit",
    //"Odometer",
    "Disk Ppu",
    "State",
    "City",
    //"Driver Name",
    "Quantity",
    "Amount",
    "Currency"
  ]

  public typeCtrl: FormControl = new FormControl();
  public length = 10;
  public message = '';
  public transactions: any;
  public start_date = new Date();
  public end_date = new Date();
  public currency = '';
  public company_address: any;
  public company_name: any;
  public company_phone: any;
  public company_contact: any;
  public total_amount: any;
  public total_tax: any;
  public company = []
  public company_filter = '';
  public company_email = '';
  public customer_avg_rebate = '';
  public tableData1: any;
  eco_avg_cost: any;
  total_qty: any;
  total_discount: any;
  trans_currency: any;

  constructor(private activatedRout: ActivatedRoute,
    public invoiceService: InvoiceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker({
        iconBase: "fa",
        tickIcon: "fa-check"
      });
    }

    setTimeout(() => {
      if ($(".selectpicker1").length != 0) {
        $(".selectpicker1").selectpicker({
          iconBase: "fa",
          tickIcon: "fa-check"
        });
      }
    }, 100);
    
    $('.datepicker1').datetimepicker({
      format: 'MM/DD/YYYY',    //use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      this.start_date = e.date.toDate();
    });

    $('.datepicker2').datetimepicker({
      format: 'MM/DD/YYYY',    //use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      this.end_date = e.date.toDate();
    });
    let date = new Date();
    this.start_date.setDate(date.getDate() - 6);
    this.activatedRout.data.subscribe(data => {
      if (data && data["data"]["message"]) {
        this.message = data["data"]['message']
      }
      this.transactions = [];
      this.company = data.data['company'];
      this.transactions.map((item)=> {
        item.card = item.card.toString().replace(/.(?=.{4})/g, '');
      })
      this.tableData1 = {
        headerRow: this.columnsHeading,
        dataRows: this.transactions
      };
    });
  }

  onDateChange(event, type) {
    if (type === 'start_date') {
      this.start_date = new Date(event.value);
    }
    if (type === 'end_date') {
      this.end_date = new Date(event.value);
    }
  }
  loadFilteredCLCData() {
    let datepipe = new DatePipe("en-US");
    let startDate = datepipe.transform(this.start_date, "yyyy-MM-dd") + 'T00:00:00Z'
    let endDate = datepipe.transform(this.end_date, "yyyy-MM-dd") + 'T00:00:00Z'
    if (!this.company_filter) {
      this.company_filter = 'All'
    }
    this.spinner.show();
    this.invoiceService.filterCLC(startDate, endDate, this.currency, this.company_filter).subscribe((data) => {
      this.spinner.hide();
      if (typeof data['data'] != 'string') {
        this.transactions = data["data"];
        this.company_name = data['company_name'];
        this.company_contact = data['company_contact'];
        this.company_email = data['company_email'];
        this.customer_avg_rebate = data['customer_avg_rebate'];
        this.eco_avg_cost = data['eco_avg_cost'];
        this.total_discount = data['total_discount'];
        this.total_qty = data['total_qty'];
        this.trans_currency = data['trans_currency'];
        this.transactions.map((item)=> {
          item.card = item.card.toString().replace(/.(?=.{4})/g, '');
        })
        this.tableData1 = {
          headerRow: this.columnsHeading,
          dataRows: this.transactions
        };

      } else {
        this.transactions = [];

      }

    })
  }

  public downloadPDF(): void {
    let datepipe = new DatePipe("en-US");
    let startDate = datepipe.transform(this.start_date, "yyyy-MM-dd") + 'T00:00:00Z'
    let endDate = datepipe.transform(this.end_date, "yyyy-MM-dd") + 'T00:00:00Z'
    if (!this.company_filter) {
      this.company_filter = 'All'
    }
    this.invoiceService.getInvoiceFileName(this.currency, startDate, endDate).subscribe(fileData => {
      let fileName = `${fileData['filename']}`;
      this.invoiceService.dowloadInvoice(this.currency, startDate, endDate, this.company_filter).subscribe(data =>
        saveAs(data as unknown as string, fileName));
    });
  }
  // _openCalendar(picker: MatDatepicker<Date>) {
  //     picker.open();
  // }

}
