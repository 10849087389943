import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { InvoiceService } from "../../services/invoice.service";
import { UpdateCardComponent } from "../update-card/update-card.component";
declare var $: any;

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: any [];
}

@Component({
  moduleId: module.id,
  selector: "app-cards",
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.css"],
})
export class CardsComponent implements OnInit {
  public displayedColumns: string[] = [
    "card",
    "drivername",
    "pin",
    "status",
    "unit",    
  ];
  public columnsHeading = {
    card: "card",
    drivername: "drivername",
    pin: "pin",
    unit: "unit",
    status: "status",
  };
  public length = 10;
  public cards: any;
  public dataTable: DataTable;

  public card = "";
  public city = "";
  constructor(
    private activatedRout: ActivatedRoute,
    public invoiceService: InvoiceService,
    public matDialog: MatDialog
  ) { }

  ngOnInit() {
    
    this.activatedRout.data.subscribe((data) => {
      this.cards = data["data"];
      this.dataTable = {
        headerRow: this.displayedColumns, //['Name', 'Position', 'Office', 'Age', 'Date', 'Actions'],
        footerRow: this.displayedColumns,//['Name', 'Position', 'Office', 'Age', 'Start Date', 'Actions'],
        dataRows :  this.cards
      };
    });
  }

  loadFilteredTCards() {
    this.invoiceService.fetchCards(this.card).subscribe((data) => {
      this.cards = data;
    });
  }

  
  openUpdateCardModal(item) {    
    const dialogRef = this.matDialog.open(UpdateCardComponent, {
      width: "800px",
      data: item,
    });
    dialogRef.afterClosed().subscribe((reload) => {
      if (reload) {
        window.location.reload();
        // this.invoiceService.fetchCards("").subscribe((data) => {
        //   this.cards = data;
        // });
      }
    });
  }

  ngAfterViewInit() {

    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search cards",
      }

    });


    var table = $('#datatables').DataTable();

    // Edit record
    table.on('click', '.edit', function () {
      var $tr = $(this).closest('tr');

      var data = table.row($tr).data();
      alert('You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.');
    });

    // Delete a record
    table.on('click', '.remove', function (e) {
      var $tr = $(this).closest('tr');
      table.row($tr).remove().draw();
      e.preventDefault();
    });

    //Like record
    table.on('click', '.like', function () {
      alert('You clicked on Like button');
    });
  }
}
