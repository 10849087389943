import {
    HttpEvent,
    HttpHandler, HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {        
        const apiReq = req.clone({            
            url: `${environment.apiUrl}${req.url}`,
        });
        return next.handle(apiReq);
    }
}
