import { Routes } from '@angular/router';
import { CardsComponent } from './components/cards/cards.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { LoginComponent } from './components/login/login.component';
import { MoneyCodeComponent } from './components/money-code/money-code.component';
import { RebateCalculatorComponent } from './components/rebate-calculator/rebate-calculator.component';
import { SupportComponent } from './components/support/support.component';
import { TransactionsComponent } from './components/transactions/transactions.component';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { CardsResolverService, InvoiceResolverService, TransactionResolverService } from './resolvers/invoice-resolver.service';
import { AuthGuard } from './services/auth.guard';

export const AppRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    // {
    //     path: '',
    //     redirectTo: 'dashboard',
    //     pathMatch: 'full',
    // }, 
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [{
            path: '',
            loadChildren: './dashboard/dashboard.module#DashboardModule'
        },
        {
            path: 'moneycode',
            canActivate: [AuthGuard],
            component: MoneyCodeComponent

        },
        {
            path: "clc",
            canActivate: [AuthGuard],
            component: RebateCalculatorComponent,
            resolve: {
                data: InvoiceResolverService,
            }
        },
        {
            path: "cards",
            canActivate: [AuthGuard],
            component: CardsComponent,
            resolve: {
                data: CardsResolverService,
            }
        },
        {
            path: "support",
            canActivate: [AuthGuard],
            component: SupportComponent,
            resolve: {

            }
        },
        {
            path: "invoice",
            component: InvoiceComponent,
            canActivate: [AuthGuard],
            resolve: {
                data: InvoiceResolverService,
            }
        },
        {
            path: "transactions",
            canActivate: [AuthGuard],
            component: TransactionsComponent,
            resolve: {
                data: TransactionResolverService,
            }
            
        },
            {
                path: "change-password",
                // canActivate: [AuthGuard],
                component: ChangePasswordComponent,
            },
            
        
        {
            path: 'components',
            loadChildren: './components/components.module#ComponentsModule'
        }, {
            path: 'forms',
            loadChildren: './forms/forms.module#Forms'
        }, {
            path: 'tables',
            loadChildren: './tables/tables.module#TablesModule'
        }, {
            path: 'maps',
            loadChildren: './maps/maps.module#MapsModule'
        }, {
            path: 'charts',
            loadChildren: './charts/charts.module#ChartsModule'
        }, {
            path: 'calendar',
            loadChildren: './calendar/calendar.module#CalendarModule'
        }, {
            path: '',
            loadChildren: './userpage/user.module#UserModule'
        }]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
