import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceService } from '../../services/invoice.service';
import { SweetAlertComponent } from '../sweetalert/sweetalert.component';


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  formGroup: FormGroup;
  public error;
  constructor(
    private formBuilder: FormBuilder,
    private invoceService: InvoiceService,
    private alertComponent: SweetAlertComponent,
    private spinner: NgxSpinnerService

  ) { }

  ngOnInit() {

    this.createForm();
    this.setChangeValidate()
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'subject': [null, Validators.required],
      'message': [null, [Validators.minLength(0), Validators.maxLength(250)]],
      'validate': ''
    });
  }
  get subject() {
    return this.formGroup.get('subject') as FormControl
  }

  setChangeValidate() {
    this.formGroup.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.formGroup.get('subject').setValidators([Validators.required, Validators.minLength(3)]);
          this.error = "Please specify subject";
        } else {
          this.formGroup.get('subject').setValidators(Validators.required);
        }
        this.formGroup.get('subject').updateValueAndValidity();
      }
    )
  }

  public onSubmit(): void {
    this.spinner.show();
    this.invoceService.sendSupportEmail(this.formGroup.value).subscribe(data => {
      this.spinner.hide();
      const title = 'Thank you!';
      const message = 'Thanks for reaching out. One of the Agent from Customer Service team will get back on your request.';

      this.alertComponent.showSwal('success-message', title, message);
      this.formGroup.reset();
    }, error => {
      this.spinner.hide();
    })
  }

}
