import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from '../services/invoice.service';
import { TransactionsService } from '../services/transactions.service';

@Injectable({
  providedIn: 'root'
})

export class InvoiceResolverService implements Resolve<any> {

  constructor(
    private _invoiceService: InvoiceService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> | Promise<any> | any {
    let page = route.params['page'];
    if (page == undefined) {
      page = 1
    }
    return this._invoiceService.fetchInvoice();
  }
}


@Injectable({
  providedIn: 'root'
})

export class TransactionResolverService implements Resolve<any> {

  constructor(private _transService: TransactionsService,) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> | Promise<any> | any {
    let page = route.params['page'];
    if (page == undefined) {
      page = 1
    }
    return this._transService.fetchTransactions();
  }
}

@Injectable({
  providedIn: 'root'
})

export class CardsResolverService implements Resolve<any> {

  constructor(private invoiceService: InvoiceService,) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> | Promise<any> | any {
    let page = route.params['page'];
    if (page == undefined) {
      page = 1
    }
    return this.invoiceService.fetchCards();
  }
}


@Injectable({
  providedIn: 'root'
})

export class CLCResolverService implements Resolve<any> {

  constructor(
    private clcService: InvoiceService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> | Promise<any> | any {
    let page = route.params['page'];
    if (page == undefined) {
      page = 1
    }
    return this.clcService.fetchCLC();
  }
}