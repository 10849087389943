import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceService } from '../../services/invoice.service';
import { SweetAlertComponent } from '../sweetalert/sweetalert.component';

@Component({
  selector: 'app-money-code',
  templateUrl: './money-code.component.html',
  styleUrls: ['./money-code.component.css']
})
export class MoneyCodeComponent implements OnInit {
  moneyCode: any;
  error: string;

  constructor(
    public invoiceService: InvoiceService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private alertComponent: SweetAlertComponent,
  ) { }

  ngOnInit() {
    this.moneyCode = {
      mc_amount: '',
      unit_number: '',
      card_number: '',
      mc_reason: '',
      trip_number: '',
      driver_name: '',

    }
  }

  onSubmit(value, isValid: boolean, form: NgForm) {
    if (isValid) {
      let requestId = Math.floor(100000 + Math.random() * 900000);
      const body = {
        ...value,
        request_id: requestId
      };
      this.spinner.show()
      this.invoiceService.sendMoneyCodeEmail(body).subscribe(data => {
        this.spinner.hide();
        const title = 'Thank you!';
        const message = `Your request is in progress. Please note the confirmation number: ${requestId} . Money code will be forwarded to email.`
        this.alertComponent.showSwal('success-message', title, message);
        this.onReset(form);
      }, (error) => {
        if (error == 'Service Unavailable') {
          this.error = 'Something went wrong with the request. Try again later or please contact Support Team -  support@ecopetroleum.ca'
        }
        this.spinner.hide();
      })
    }
  }

  onReset(form) {
    form.resetForm();
  }
}
