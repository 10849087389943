import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TransactionsService } from "app/services/transactions.service";

declare var $: any;

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {
  public changed = true;
  public template: any;
  public error = "";
  public selectedFields = [];
  columns = [];

  selectedColumns = [];

  constructor(
    public dialogRef: MatDialogRef<CreateTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public transactionService: TransactionsService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.error = "";
    this.columns = this.data;
    this.template = {
      template_name: '',
    }
  }

  public onCloseClick(e) {
    e.preventDefault();
    this.dialogRef.close(false);
  }

  // handle template creation
  public onSubmit(body, isValid) {
    if (isValid) {
      body['template_create'] = "yes";
      body['template_columns'] = this.selectedColumns;
      this.spinner.show();
      this.transactionService.createTemplate(body).subscribe(
        (data) => {
          this.spinner.hide();
          this.dialogRef.close(true);
        },
        (error) => {
          if (error == 'Service Unavailable') {
            this.error = "Something went wrong, please try again later. For urgencies contact; support@ecopetroleum.ca"
          }
          this.spinner.hide();
        }
      );
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
}
