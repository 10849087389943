import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "../app.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  private token: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private _sharedService: SharedService) {
    let sharedData = _sharedService.get();
    this.token = sharedData["auth_token"];
    this.httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json",
        Authorization: "token " + this.token,
      }),
    };
  }
  public fetchInvoice() {
    let start_date = new Date();
    let date = start_date.setDate(start_date.getDate() - 6);
    let end_date = new Date();
    let datepipe = new DatePipe("en-US");
    let startDate = datepipe.transform(date, "yyyy-MM-dd") + "T00:00:00Z";
    let endDate = datepipe.transform(end_date, "yyyy-MM-dd") + "T00:00:00Z";

    return this.http.get(
      `/api/v1/app/invoice?&date_after=${startDate}&date_before=${endDate}`,
      //'/api/v1/app/invoice?currency=CAD&date_after=2021-05-01T00:00:00Z&date_before=2021-10-16T00:00:00Z&company_name=All',
      this.httpOptions
    );
  }

  public filterCLC(startDate, endDate, currency, company) {
    if(currency == '') {
      currency = 'CAD';
      return this.http.get(
        `/api/v1/app/crebates?currency=USD&date_after=${startDate}&date_before=${endDate}&company_name=${company}&currency=${currency}`,
        this.httpOptions
      );
    } else {
      return this.http.get(
        `/api/v1/app/crebates?&date_after=${startDate}&date_before=${endDate}&company_name=${company}&currency=${currency}`,
        this.httpOptions
      );
    }
  }

  public fetchCLC() {
    let start_date = new Date();
    let date = start_date.setDate(start_date.getDate() - 6);
    let end_date = new Date();
    let datepipe = new DatePipe("en-US");
    let startDate = datepipe.transform(date, "yyyy-MM-dd") + "T00:00:00Z";
    let endDate = datepipe.transform(end_date, "yyyy-MM-dd") + "T00:00:00Z";

    return this.http.get(
      `/api/v1/app/crebates?currency=USD&date_after=${startDate}&date_before=${endDate}&company_name='All'}`,
      this.httpOptions
    );
  }

  public filterInvoice(startDate, endDate, currency, company, product) {
    if (!product || product == '') {
      return this.http.get(
        `/api/v1/app/invoice?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}`,
        this.httpOptions
      );
    } else if (product == 'gasoline') {
      return this.http.get(
        `/api/v1/app/invoice?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}&item=REG_GAS`,
        this.httpOptions
      );
    } else if (product == 'diesel') {
      return this.http.get(
        `/api/v1/app/invoice?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}&item_not=MC`,
        this.httpOptions
      );
    } else if (product == 'moneycode') {
      return this.http.get(
        `/api/v1/app/invoice?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}&item=MC`,
        this.httpOptions
      );
    }
  }
  public fetchCards(cardNumber = "") {
    return this.http.get(
      `/api/v1/app/card?card=${cardNumber}`,
      this.httpOptions
    );
  }
  public getInvoiceFileName(currency, startDate, endDate, company='', product='') {  

    if (!product || product == '') {
      return this.http.get(
        `/api/v1/app/invoice_filename?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}`,
        this.httpOptions
      );
    } else if (product == 'gasoline') {
      return this.http.get(
        `/api/v1/app/invoice_filename?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}&item=REG_GAS`,
        this.httpOptions
      );
    } else if (product == 'diesel') {
      return this.http.get(
        `/api/v1/app/invoice_filename?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}&item_not=MC`,
        this.httpOptions
      );
    } else if (product == 'moneycode') {
      return this.http.get(
        `/api/v1/app/invoice_filename?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}&item=MC`,
        this.httpOptions
      );
    }

  }

  public checkRoleStaff() {
    return this.http.get(`/api/v1/app/checkrole_staff`, this.httpOptions);
  }

  public changePassword(body) {
    return this.http.put(`/api/v1/app/upass`, body, this.httpOptions);
  }

  public sendSupportEmail(body) {
    return this.http.post(`/send-email`, body, this.httpOptions);
  }

  public sendMoneyCodeEmail(body) {
    return this.http.post(`/send-email-mc?mc_amount=${body.mc_amount}&unit_number=${body.unit_number}&mc_reason=${body.mc_reason}&request_id=${body.request_id}&trip_number=${body.trip_number}&driver_name=${body.driver_name}`, {}, this.httpOptions);
  }

  public updateCardDetails(body) {
    return this.http.patch(`/api/v1/app/card_update`, body, this.httpOptions);
  }

  public dowloadInvoice(currency, startDate, endDate, company) {
    return this.http.get(
      `/invoce/download?currency=${currency}&date_after=${startDate}&date_before=${endDate}&company_name=${company}`,
      { ...this.httpOptions, responseType: "blob" }
    );
  }
}
