import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  public currentUserValue() {
    return this.currentUser;
  }

  login(company: string, password: string) {
    return this.http.post<any>(`/api/v1/auth`, { company, password })
      .pipe(map(user => {
        if (user && user.token) {      
          sessionStorage.setItem('currentUser', JSON.stringify(user));
        }
        return user;
      }));
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    //this.currentUserSubject.next(null);
  }
}
