import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { SharedService } from "../app.service";
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  private token: string;
  private httpOptions: any;

  constructor(
    private http: HttpClient,
    private _sharedService: SharedService
  ) {
    let sharedData = _sharedService.get();
    this.token = sharedData["auth_token"];
    this.httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json",
        Authorization: "token " + this.token
      })
    };
  }
  public fetchTransactions() {
    let start_date = new Date();
    let date = start_date.setDate(start_date.getDate() - 6);
    let end_date = new Date();
    let datepipe = new DatePipe("en-US");
    let startDate = datepipe.transform(date, "yyyy-MM-dd") + 'T00:00:00Z'
    let endDate = datepipe.transform(end_date, "yyyy-MM-dd") + 'T00:00:00Z'
    return this.http.get(
      //`/api/v1/app/invoice?date_after=2020-07-24T00:00:00Z&currency=USD`,
      `/api/v1/app/trans?date_after=${startDate}&date_before=${endDate}`,
      //'/api/v1/app/trans?currency=&date_after=2021-03-01T00:00:00Z&date_before=2021-10-16T00:00:00Z&card=&city=&unit=&driver_name=',
      this.httpOptions
    );
  }
  public filterTransactions(startDate, 
    endDate,
    currency,
    card,
    city,
    unit,
    driver_name,
    product,
    company_filter,
    ) {
    if (!product || product == '') {
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&format=json&company=${company_filter}`,
        this.httpOptions
      );
    } else if (product == 'gasoline') {
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&item=REG_GAS&format=json&company=${company_filter}`,
        this.httpOptions
      );
    } else if (product == 'diesel'){
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&item_not=MC&format=json&company=${company_filter}`,
        this.httpOptions
      );
    } else if(product == 'moneycode') {
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&item=MC&format=json&company=${company_filter}`,
        this.httpOptions
      );
    } 

    
  }
  public downloadReport(startDate, 
    endDate,
    currency,
    card,
    city,
    unit,
    driver_name,
    product,
    company_filter,
    export_template,
    format
    ) {
    const customOptions = {
      ...this.httpOptions,
      responseType: 'blob',
    };

    if (!product || product == '') {
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&export_format=${format}&company=${company_filter}&export_template=${export_template}`,
        customOptions
      );
    } else if (product == 'gasoline') {
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&item=REG_GAS&export_format=${format}&company=${company_filter}&export_template=${export_template}`,
        customOptions
      );
    } else if (product == 'diesel'){
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&item_not=MC&export_format=${format}&company=${company_filter}&export_template=${export_template}`,
        customOptions
      );
    } else if(product == 'moneycode') {
      return this.http.get(
        `/api/v1/app/trans?currency=${currency}&date_after=${startDate}&date_before=${endDate}&card=${card}&city=${city}&unit=${unit}&driver_name=${driver_name}&item=MC&export_format=${format}&company=${company_filter}&export_template=${export_template}`,
        customOptions
      );
    }
  }

  public createTemplate(body) {
    return this.http.post(`/api/v1/app/trans`, body, this.httpOptions);
  }
}
