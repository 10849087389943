import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TransactionsService } from "../../services/transactions.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { CreateTemplateComponent } from "../create-template/create-template.component";


declare var $: any;

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  public displayedColumns: string[] = [
    "company_name",
    "tran_date",
    "card",
    "city",
    "unit",
    // "odometer",
    "state",
    // "driver_name",
    "disc_ppu",
    "qty",
    // "pretax",
    // "tax",
    "amt",
    "currency",
  ];
  public columnsHeading = [
    "Company Id",
    "Tansaction Date",
    "Card",
    "City",
    "Unit",
    //"Odometer",
    "State",
    // "Driver Name",
    "Disc Ppu",
    "Quantity",
    //"PreTax",
    //"Tax",
    "Amount",
    "Currency",
  ];
  public length = 10;
  public transactions: any;
  public start_date = new Date();
  public end_date = new Date();
  public currency = '';
  public product = "";
  public format = "";
  public card = "";
  public message = "";
  public city = "";
  public unit = "";
  public driver_name = "";
  public excelData: any;
  public maxDate: any;
  public template = "";
  public templates = [];
  public excel = [];
  public tableData1: any;
  public company_filter = "";
  public company = [];
  public fields = [];
  constructor(
    private activatedRout: ActivatedRoute,
    public transService: TransactionsService,
    private spinner: NgxSpinnerService,
    public matDialog: MatDialog,
  ) {

  }

  ngOnInit() {
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker({
        iconBase: "fa",
        tickIcon: "fa-check"
      });
    }
    setTimeout(() => {
      if ($(".selectpicker1").length != 0) {
        $(".selectpicker1").selectpicker({
          iconBase: "fa",
          tickIcon: "fa-check"
        });
      }
    }, 100);

    setTimeout(() => {
      if ($(".selectpicker2").length != 0) {
        $(".selectpicker2").selectpicker({
          iconBase: "fa",
          tickIcon: "fa-check"
        });
      }
    }, 100);

    $('.datepicker1').datetimepicker({
      format: 'MM/DD/YYYY',    //use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      this.start_date = e.date.toDate();
    });

    $('.datepicker2').datetimepicker({
      format: 'MM/DD/YYYY',    //use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      this.end_date = e.date.toDate();
    });

    let date = new Date();
    this.start_date.setDate(date.getDate() - 6);
    this.activatedRout.data.subscribe((data) => {
      this.templates = data.data["templates"];
      this.company = data.data['companies'];
      this.transactions = data.data["transactions"];
      this.fields = data.data['fields_possible'];
      this.currency = "";
      this.driver_name = "";

      this.transactions.map((item) => {
        item.full_card = item.card
        item.card = item.card.toString().replace(/.(?=.{4})/g, '');
      })

      this.tableData1 = {
        headerRow: this.columnsHeading,
        dataRows: this.transactions
      };
    });
  }
  onDateChange(event, type) {
    if (type === "start_date") {
      this.start_date = new Date(event.value);
    }
    if (type === "end_date") {
      this.end_date = new Date(event.value);
    }
  }

  loadFilteredTransactions() {
    let datepipe = new DatePipe("en-US");
    let startDate =
      datepipe.transform(this.start_date, "yyyy-MM-dd") + "T00:00:00Z";
    let endDate =
      datepipe.transform(this.end_date, "yyyy-MM-dd") + "T00:00:00Z";
      if (!this.company_filter) {
      this.company_filter = "All";
    }
    this.spinner.show();
    this.transService
      .filterTransactions(startDate,
        endDate,
        this.currency,
        this.card,
        this.city, this.unit,
        this.driver_name,
        this.product,
        this.company_filter,
        )
      .subscribe((data) => {
        this.spinner.hide();
        if (data && data["message"]) {
          this.message = data["message"];
        }
        
        this.transactions = data['transactions'];
        this.transactions.map((item) => {
          item.full_card = item.card
          item.card = item.card.toString().replace(/.(?=.{4})/g, '');
        })
        this.tableData1 = {
          headerRow: this.columnsHeading,
          dataRows: this.transactions
        };
      }, error => {
        this.spinner.hide();
      });
  }

  formatReport(date) {
    const format ='yyyy-MM-dd_hh-mm-ss';
    return new DatePipe('en-US').transform(date, format, "UTC");
  }

  getFileName() {
    const currentTime = new Date();
    let fileExtension = '';
    if (this.format == 'csv') {
      fileExtension = `.csv`;
    } else if (this.format == 'dat') {
      fileExtension = `.dat`;
    } else {
      fileExtension = `.xlsx`;
    }
    return `Report_${this.formatReport(currentTime)}${fileExtension}`
  }

  downloadTransactions() {
    let datepipe = new DatePipe("en-US");
    let startDate =
      datepipe.transform(this.start_date, "yyyy-MM-dd") + "T00:00:00Z";
    let endDate =
      datepipe.transform(this.end_date, "yyyy-MM-dd") + "T00:00:00Z";
      if (!this.company_filter) {
      this.company_filter = "All";
    }
    this.spinner.show();
    this.transService
      .downloadReport(startDate,
        endDate,
        this.currency,
        this.card,
        this.city, this.unit,
        this.driver_name,
        this.product,
        this.company_filter,
        this.template,
        this.format
        )
      .subscribe((data) => {
        const blob = new Blob([data]);
        let downloadURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.getFileName();
        link.click();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }

  openCreateTemplateModal() {    
    const dialogRef = this.matDialog.open(CreateTemplateComponent, {
      width: "1000px",
      data: this.fields,
    });
    dialogRef.afterClosed().subscribe((reload) => {
      if (reload) {
        window.location.reload();
      }
    });
  }
}
