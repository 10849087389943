import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public sharedData = {};
  constructor() {
  }

  set(key, value) {
    this.sharedData[key] = value;
  }

  get() {
    return this.sharedData;
  }
}
