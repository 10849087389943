import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SharedService } from '../../app.service';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  form: FormGroup;
  private formSubmitAttempt: boolean;
  public invalidCred = '';
  public loginImg = 'https://ecoapp-static.s3.amazonaws.com/static/images/login.jpg';


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private spinner: NgxSpinnerService

  ) {
    // redirect to home if already logged in
    if (this.authService.currentUserValue()) {
      this.router.navigate(['/dashboard']);
    }
  }
  ngOnInit() {

    this.checkFullPageBackgroundImage();

    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700)
    this.form = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required]
    });

  }



  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }


  onSubmit() {
    this.loading = true;
    this.invalidCred = '';
    if (this.form.valid) {
      this.spinner.show();
      this.authService.login(this.form.value.userName, this.form.value.password)
        .pipe(first())
        .subscribe(
          data => {
            this.spinner.hide();
            sessionStorage.setItem('company', this.form.value.userName)
            this.router.navigate(['/home']);
            window.location.reload();
          },
          error => {
            this.invalidCred = 'Invalid Username or Password. Please try again with right credentials.';
            this.loading = false;
            this.spinner.hide();
          });
    }
    this.formSubmitAttempt = true;

  }

  save1(model, isValid: boolean) {
    // call API to save customer
    if (isValid) {
      console.log(model, isValid);
    }
  }

  test: Date = new Date();

  checkFullPageBackgroundImage() {
    var $page = $('.full-page');
    var image_src = $page.data('image');

    if (image_src !== undefined) {
      var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
      $page.append(image_container);
    }
  };
}
