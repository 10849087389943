import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { InvoiceService } from '../../services/invoice.service';
import { SweetAlertComponent } from '../sweetalert/sweetalert.component';
import { NgxSpinnerService } from "ngx-spinner";


export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})


export class ChangePasswordComponent implements OnInit {

  passwordForm: FormGroup;
  public loading = false;
  public disabled = true;
  public matcher = new PasswordErrorStateMatcher();
  public errors = '';
  public passwordError;

  constructor(
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,
    private router: Router,
    private alertComponent: SweetAlertComponent,
    private spinner: NgxSpinnerService

  ) {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    });
  }
  ngOnInit() {

  }

  savePassword(formBody) {
    let body = {
      old_password: formBody.password,
      new_password: formBody.confirmPassword
    }
    this.spinner.show();
    this.invoiceService.changePassword(body).subscribe(data => {
      this.spinner.hide();
      this.alertComponent.showSwal('password-changed');
    },
      err => {
        this.passwordError = 'Something went wrong';
      })
  }

}
